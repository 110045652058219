import MulticoinValidator from 'multicoin-address-validator';

/**
 * A very basic check to see if a string could be a crypto address.
 * Used as a first check before doing more stringent validation.
 *
 * BTC / ETH - checks for hex number between 20 and 44 chars, with optional
 *             0x in front
 * BSC / CELO / POLYGON - same check as ETH
 * SOL - https://docs.solana.com/integrations/exchange
 * @param value
 * @returns
 */

export const isPossibleAddress = (value: string): boolean =>
  isBTCAddress(value) ||
  isETHAddress(value) ||
  isTRXAddress(value) ||
  isSOLAddress(value) ||
  isHBARAddress(value) ||
  isADAAddress(value) ||
  isLTCAddress(value) ||
  isAVAXAddress(value) ||
  isMVXAddress(value) ||
  isATTNAddress(value) ||
  isALGOAddress(value);

export const isBTCAddress = (value: string): boolean =>
  MulticoinValidator.validate(value, 'btc');
export const isETHAddress = (value: string): boolean =>
  MulticoinValidator.validate(value, 'eth');
export const isTRXAddress = (value: string): boolean =>
  MulticoinValidator.validate(value, 'trx');
export const isSOLAddress = (value: string): boolean =>
  MulticoinValidator.validate(value, 'sol');
export const isHBARAddress = (value: string): boolean =>
  /^(\d[.]\d[.]\d+)$/.test(value); // https://docs.hedera.com/guides/core-concepts/accounts#accounts
export const isADAAddress = (value: string): boolean =>
  MulticoinValidator.validate(value, 'ada');
export const isLTCAddress = (value: string): boolean =>
  MulticoinValidator.validate(value, 'ltc');
export const isAVAXAddress = (value: string): boolean =>
  MulticoinValidator.validate(value, 'avax');
export const isMVXAddress = (value: string): boolean =>
  /^erd1[a-z0-9]{58}$/i.test(value); // https://docs.multiversx.com/technology/glossary
export const isATTNAddress = (value: string): boolean =>
  /^0x[a-fA-F0-9]{40}$/.test(value); // only works over ETH network
export const isALGOAddress = (value: string): boolean =>
  MulticoinValidator.validate(value, 'algo');
