import clsx from 'clsx';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import {
  BidirectionalVoting,
  Button,
  CustomContentLoader,
  FlagReport,
  Link,
  ReportedSection,
  Text,
} from '@/components';
import { FeatherIconName } from '@/components/icon';
import { LexicalViewer } from '@/components/lexical-viewer';
import { CommentSection } from '@/features/comments';
import { useMe } from '@/hooks';
import {
  getRouteForEditReport,
  getRouteForProfile,
  PARTNER_RANSOMWHERE_LINK,
} from '@/types/routes';
import {
  getDisplayStringForScamCategory,
  ScamCategory,
} from '@/types/scam-categories';
import type { ScamReport } from '@/types/scam-report';
import {
  getDisplayStringForReportSource,
  ReportSource,
} from '@/types/scam-report-source';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { getHumanReadbleDateString } from '@/utils/ago-on-string';
import { Mobile, Tablet } from '@/utils/responsive';
import { LossesSection } from '..';
import { PrivateReportTooltip } from '../scam-report-card/components/private-report-tooltip';
import { LabeledInfo } from './components';

export type ScamReportDetailsProps = StyleProps &
  ScamReport & {
    /**
     * Whether the component is loading
     */
    isLoading?: boolean;

    /**
     * Whether to show a comment section in the detail card
     * @default false
     */
    shouldShowCommentsInCard?: boolean;
  };

const ROOT = makeRootClassName('ScamReportDetails');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  shouldShowCommentsInCard: false,
} as const;

function ScamReportDetailsLoading(): ReactElement {
  const loadingProps = {
    backgroundColor: CustomContentLoader.BACKGROUND_COLOR,
    foregroundColor: CustomContentLoader.FOREGROUND_COLOR,
    speed: 1,
  };

  const rectProps = {
    rx: CustomContentLoader.RADIUS,
    ry: CustomContentLoader.RADIUS,
  };

  return (
    <div className={ROOT}>
      <div className={el`content`}>
        <div className={el`preview-info`}>
          <div className={el`category-description`}>
            <ContentLoader {...loadingProps} viewBox="0 0 350 500">
              <rect {...rectProps} x="0" y="0" width="200" height="30" />
              <rect {...rectProps} x="0" y="56" width="350" height="14" />
              <rect {...rectProps} x="0" y="78" width="325" height="14" />
              <rect {...rectProps} x="0" y="112" width="115" height="24" />
              <rect {...rectProps} x="0" y="144" width="260" height="14" />
              <rect {...rectProps} x="0" y="180" width="115" height="24" />
              <rect {...rectProps} x="0" y="212" width="120" height="140" />
              <rect {...rectProps} x="130" y="212" width="120" height="140" />
              <rect {...rectProps} x="0" y="372" width="165" height="24" />
              <rect {...rectProps} x="0" y="404" width="80" height="16" />
              <rect {...rectProps} x="86" y="404" width="65" height="16" />
              <rect {...rectProps} x="0" y="432" width="26" height="8" />
              <rect {...rectProps} x="0" y="450" width="96" height="16" />
              <rect {...rectProps} x="120" y="432" width="26" height="8" />
              <rect {...rectProps} x="120" y="450" width="110" height="16" />
            </ContentLoader>
            <Mobile className={el`edit-vote`}>
              <div className={el`vote-section`}>
                <BidirectionalVoting
                  size="small"
                  direction="horizontal"
                  isDisabled={true}
                  netVoteCount={0}
                  reportId=""
                />
              </div>
            </Mobile>
          </div>
          <Tablet className={el`vote-section`}>
            <BidirectionalVoting
              isDisabled={true}
              netVoteCount={0}
              reportId=""
            />
          </Tablet>
        </div>
      </div>
    </div>
  );
}

function renderSubmittedBy(p: ScamReportDetailsProps): ReactElement {
  switch (p.source) {
    case ReportSource.REST_API:
    case ReportSource.CHAINABUSE:
    case ReportSource.PARTNER_LEDGER:
    case ReportSource.PARTNER_SAFEPAL:
    case ReportSource.PARTNER_1INCH:
    case ReportSource.PARTNER_1INCH_ANDROID:
    case ReportSource.PARTNER_1INCH_IOS:
    case ReportSource.PARTNER_BLOKK:
    case ReportSource.PARTNER_ACTION_FRAUD:
    case ReportSource.PARTNER_DANY:
    case ReportSource.PARTNER_FBI:
    case ReportSource.PARTNER_SANJOSECA_GOV:
    case ReportSource.PARTNER_OPP:
    case ReportSource.PARTNER_MTV:
    case ReportSource.PARTNER_COINBASE:
    case ReportSource.PARTNER_RCMP:
    case ReportSource.PARTNER_NYPD:
    case ReportSource.PARTNER_BITFLY:
    case ReportSource.PARTNER_SOLANAFM:
    case ReportSource.PARTNER_METAMASK:
    case ReportSource.PARTNER_AVASCAN:
    case ReportSource.PARTNER_WEBACY:
      return (
        <>
          <LabeledInfo label="By">
            <Link href={getRouteForProfile(p.authorUsername)}>
              {p.authorUsername}
            </Link>
          </LabeledInfo>
        </>
      );
    case ReportSource.BITCOINABUSE:
      return (
        <>
          <LabeledInfo label="In">
            <Text type="body-sm">
              {getDisplayStringForReportSource(p.source)}
            </Text>
          </LabeledInfo>
        </>
      );

    case ReportSource.PARTNER_RANSOMWHERE:
      return (
        <>
          <LabeledInfo label="By">
            <Link href={PARTNER_RANSOMWHERE_LINK} isExternal>
              Ransomwhe.re
            </Link>
          </LabeledInfo>
        </>
      );

    default:
      throw new Error(`Unknown ReportSource variant: ${p.source}`);
  }
}

function ScamReportDetails(props: ScamReportDetailsProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const { me } = useMe();
  const router = useRouter();
  const { push } = router;

  if (p.isLoading) {
    return <ScamReportDetailsLoading />;
  }

  const isMyReport = !!me && me.username === p.authorUsername;
  // hide actions for non-author for private reports
  const hideActions = !isMyReport && p.isPrivate;

  return (
    <div className={clsx(ROOT, p.className)}>
      <div className={el`content`}>
        {p.isPrivate && <PrivateReportTooltip isAuthor={isMyReport} />}
        <div className={el`preview-info`}>
          <div className={el`category-description`}>
            <div className={el`category-vote`}>
              <h3 className={el`category`}>
                {getDisplayStringForScamCategory(p.category)}
                {p.category === ScamCategory.OTHER && p.categoryDescription && (
                  <>
                    :{' '}
                    <Text
                      as="span"
                      type="custom"
                      className={clsx(el`other-category-label`, 'custom')}
                    >
                      {p.categoryDescription && p.categoryDescription}
                    </Text>
                  </>
                )}
              </h3>
              <Tablet>
                {isMyReport && (
                  <Button
                    variant="text-white"
                    startFeatherIcon={FeatherIconName.EDIT}
                    onPress={() => push(getRouteForEditReport(p.reportId))}
                    className={el`edit-button`}
                  >
                    Edit Report
                  </Button>
                )}
              </Tablet>
              <Mobile className={el`edit-vote`}>
                {isMyReport && (
                  <Button
                    variant="text-white"
                    size="xs"
                    startFeatherIcon={FeatherIconName.EDIT}
                    onPress={() => push(getRouteForEditReport(p.reportId))}
                    className={el`edit-button`}
                  >
                    Edit Report
                  </Button>
                )}

                {!hideActions && (
                  <>
                    <div className={el`vote-section`}>
                      <BidirectionalVoting
                        size="small"
                        direction="horizontal"
                        vote={p.vote}
                        netVoteCount={p.netVoteCount}
                        reportId={p.reportId}
                      />
                    </div>
                    <FlagReport
                      reportId={p.reportId}
                      variant="mobile"
                      className={el`flag-report-mobile`}
                    />
                  </>
                )}
              </Mobile>
            </div>
          </div>
          <Tablet className={el`vote-section`}>
            {!hideActions && (
              <BidirectionalVoting
                vote={p.vote}
                netVoteCount={p.netVoteCount}
                reportId={p.reportId}
              />
            )}
          </Tablet>
        </div>

        <div
          className={clsx(el`report-info`, {
            'is-private': p.isPrivate,
          })}
        >
          <div className={el`report-info-section`}>
            <Text type="body-md" className={el`description`}>
              <LexicalViewer
                serializedEditorState={p.lexicalSerializedDescription}
                fallbackText={p.description}
              />
            </Text>
          </div>

          <LossesSection
            reportId={p.reportId}
            className={el`report-info-section`}
          />
          <div className={el`info-wrapper`}>
            <div className={el`detailed-info`}>
              <div className={el`info-row`}>
                <LabeledInfo label="Submitted">
                  {getHumanReadbleDateString(p.reportDate)}
                </LabeledInfo>
                {renderSubmittedBy(p)}
              </div>
            </div>

            <Tablet>
              <FlagReport reportId={p.reportId} className={el`flag-report`} />
            </Tablet>
          </div>
          <ReportedSection addresses={p.addresses} variant="expanded" />
        </div>

        {p.shouldShowCommentsInCard && (
          <CommentSection
            reportId={p.reportId}
            className={el`comments-section`}
          />
        )}
      </div>
    </div>
  );
}

export default ScamReportDetails;
export { ScamReportDetailsLoading };
